<template>
  <v-container
    fluid
    fill-height
    :class="{ 'pa-0 grey lighten-4': $vuetify.breakpoint.mdAndUp }"
  >
    <v-row
      justify="center"
      class="fill-height"
      :class="{ 'py-9': !$vuetify.breakpoint.mdAndUp }"
    >
      <v-col
        cols="12"
        class="d-flex flex-column"
        :class="{ 'pa-0 grey lighten-4': $vuetify.breakpoint.mdAndUp }"
      >
        <img
          v-if="showResetPasswordScreen"
          width="160"
          class="py-lg-9 py-md-9 mt-sm-12 mt-xs-12 mx-auto"
          src="@/assets/images/logo.png"
          alt="Dig Logix Logo"
        />
        <v-container v-if="showResetPasswordScreen" fluid>
          <v-row justify="center" no-gutters class="pb-16">
            <v-col col="12" lg="6" md="8" class="white pa-0 rounded-lg">
              <SetPassword
                :password-body-text="resetPasswordObject.passwordBodyText"
                :password-header-text="resetPasswordObject.passwordHeaderText"
                :password-button-text="resetPasswordObject.passwordButtonText"
                :password-body-width="resetPasswordObject.passwordBodyWidth"
                @setUserPassword="setScreens(false, true, false)"
              />
            </v-col>
          </v-row>
        </v-container>
        <ConfirmationMessage
          v-if="showConfirmationMessage"
          :icon="confirmationObject.Icon"
          :text="confirmationObject.text"
          :button="confirmationObject.button"
          @backToSignIn="backToSignIn"
        />
      </v-col>
    </v-row>
    <CompanyFooter :show-full-width="true" />
  </v-container>
</template>

<script>
import ERRORS from "@/static/errors.json";
import { getKeys, setAuthFromParams } from "@/store/utils/utils";
const passwordChangeObject = {
  footerLink: {
    footerLinkLabel: "noThanks",
    footerLinkPath: "/signin",
  },
  text: {
    bodyLabel: "You can use your new password to Sign In to your account.",
    headerLabel: "passwordChanged",
  },
  hrLabel: "or",
  button: {
    buttonLabel: "backToSignIn",
    buttonColor: "#0073D1",
    buttonOutlined: false,
    buttonEvent: "backToSignIn",
  },
  Icon: {
    iconName: "mdi-lock",
    iconColor: "success",
    isIcon: true,
  },
};
export default {
  name: "PasswordMain",
  components: {
    ConfirmationMessage: () =>
      import("../../molecules/ConfirmationMessage.vue"),
    SetPassword: () => import("../signUp/set-password.vue"),
    CompanyFooter: () => import("../../molecules/CompanyFooter.vue"),
  },
  props: {},
  data: () => ({
    showResetPasswordScreen: false,
    showScucessMessage: false,
    showErrorMessage: false,
    resetPasswordObject: {
      passwordBodyText: "changeNewPassword",
      passwordHeaderText: "resetYourPassword",
      passwordButtonText: "resetPassword",
      passwordBodyWidth: "12",
    },
  }),
  computed: {
    confirmationObject() {
      return passwordChangeObject;
    },
    showConfirmationMessage() {
      return this.showScucessMessage || this.showErrorMessage;
    },
  },
  async created() {
    this.setAuthFromURL();
    const userData = await this.$store.dispatch("getUserDataById");
    if (
      userData.status === "error" &&
      (userData.error === ERRORS.AUTHORIZATION_REQUIRED ||
        userData.error === ERRORS.JWT_EXPIRED ||
        userData.error === ERRORS.INVALID_TOKEN ||
        userData.error.includes(ERRORS.UNEXPECTED_TOKEN))
    ) {
      this.$router.push({
        path: "/signin",
        query: { passwordLinkInvalid: "true" },
      }).catch();
    } else {
      this.setScreens(true, false, false);
    }
  },
  methods: {
    setAuthFromURL() {
      const urlParams = new URLSearchParams(location.search);
      const keys = getKeys(urlParams);
      if (keys.length && keys.includes("token")) {
        setAuthFromParams(urlParams);
      }
    },
    setScreens(resetPassword, sucess, error) {
      this.showResetPasswordScreen = resetPassword;
      this.showScucessMessage = sucess;
      this.showErrorMessage = error;
    },
    backToSignIn() {
      this.$router.push("/signin").catch();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
