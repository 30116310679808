var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      class: { "pa-0 grey lighten-4": _vm.$vuetify.breakpoint.mdAndUp },
      attrs: { fluid: "", "fill-height": "" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "fill-height",
          class: { "py-9": !_vm.$vuetify.breakpoint.mdAndUp },
          attrs: { justify: "center" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column",
              class: { "pa-0 grey lighten-4": _vm.$vuetify.breakpoint.mdAndUp },
              attrs: { cols: "12" },
            },
            [
              _vm.showResetPasswordScreen
                ? _c("img", {
                    staticClass: "py-lg-9 py-md-9 mt-sm-12 mt-xs-12 mx-auto",
                    attrs: {
                      width: "160",
                      src: require("@/assets/images/logo.png"),
                      alt: "Dig Logix Logo",
                    },
                  })
                : _vm._e(),
              _vm.showResetPasswordScreen
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pb-16",
                          attrs: { justify: "center", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "white pa-0 rounded-lg",
                              attrs: { col: "12", lg: "6", md: "8" },
                            },
                            [
                              _c("SetPassword", {
                                attrs: {
                                  "password-body-text":
                                    _vm.resetPasswordObject.passwordBodyText,
                                  "password-header-text":
                                    _vm.resetPasswordObject.passwordHeaderText,
                                  "password-button-text":
                                    _vm.resetPasswordObject.passwordButtonText,
                                  "password-body-width":
                                    _vm.resetPasswordObject.passwordBodyWidth,
                                },
                                on: {
                                  setUserPassword: function ($event) {
                                    return _vm.setScreens(false, true, false)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showConfirmationMessage
                ? _c("ConfirmationMessage", {
                    attrs: {
                      icon: _vm.confirmationObject.Icon,
                      text: _vm.confirmationObject.text,
                      button: _vm.confirmationObject.button,
                    },
                    on: { backToSignIn: _vm.backToSignIn },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("CompanyFooter", { attrs: { "show-full-width": true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }